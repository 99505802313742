(function() {
    'use strict';

    angular.module('adminApp.users')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'users',
            url: '/users/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'users.view',
            url: '/:id/view',
            templateUrl: 'view/users.view',
            data: {
                sessionCheck: true,
                niceName: 'User Account ({id})'
            },
            resolve: {
                edit: function($stateParams) {
                    return $stateParams.edit;
                }
            },
            params: {
                edit: false
            },
        }).state({
            name: 'users.view.infuze',
            url: '/infuze',
            views: {
                'users-infuze': {
                    templateUrl: 'view/users.tabs.infuze'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User InFuze Licenses ({id})'
            }
        }).state({
            name: 'users.view.onsite',
            url: '/onsite',
            views: {
                'users-onsite': {
                    templateUrl: 'view/users.tabs.onsite'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User OnSite Licenses ({id})'
            }
        }).state({
            name: 'users.view.insight',
            url: '/insight',
            views: {
                'users-insight': {
                    templateUrl: 'view/users.tabs.insight'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User InSight Licenses ({id})'
            }
        }).state({
            name: 'users.view.data',
            url: '/data',
            views: {
                'users-data': {
                    templateUrl: 'view/users.tabs.data'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User Data Licenses ({id})'
            }
        }).state({
            name: 'users.view.cloud',
            url: '/cloud',
            views: {
                'users-cloud': {
                    templateUrl: 'view/users.tabs.cloud'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User Cloud Subscriptions ({id})'
            }
        }).state({
            name: 'users.view.wjs',
            url: '/wjs',
            views: {
                'users-wjs': {
                    templateUrl: 'view/users.tabs.wurfljs'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User WURFL.js Subscriptions ({id})'
            }
        }).state({
            name: 'users.view.wit',
            url: '/imageengine',
            views: {
                'users-wit': {
                    templateUrl: 'view/users.tabs.wit'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User ImageEngine Subscriptions ({id})'
            }
        }).state({
            name: 'users.view.logins',
            url: '/logins',
            views: {
                'users-logins': {
                    templateUrl: 'view/users.tabs.logins'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User Recent Logins ({id})'
            }
        }).state({
            name: 'users.view.downloads',
            url: '/downloads',
            views: {
                'users-downloads': {
                    templateUrl: 'view/users.tabs.downloads'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User Recent Downloads ({id})'
            }
        }).state({
            name: 'users.view.filex',
            url: '/filex',
            views: {
                'users-filex': {
                    templateUrl: 'view/users.tabs.filex'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User FileX ({id})'
            }
        }).state({
            name: 'users.list',
            url: '/list?page',
            templateUrl: 'view/users.list',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Users Management'
            }
        }).state({
            name: 'users.myaccount',
            url: '/:id/myaccount',
            templateUrl: 'view/users.myaccount',
            data: {
                sessionCheck: true,
                niceName: 'My Account ({id})'
            }
        }).state({
            name: 'users.supporttiers',
            url: '/supporttiers?page',
            templateUrl: 'view/users.supporttiers',
            data: {
                sessionCheck: true,
                niceName: 'Update Support Tiers'
            },
            params: {
                page: {
                    value: '1'
                }
            }
        }).state({
            name: 'users.teams',
            url: '/teams?page',
            templateUrl: 'view/users.teams',
            data: {
                sessionCheck: true,
                niceName: 'Teams Management'
            },
            params: {
                page: {
                    value: '1'
                }
            }
        }).state({
            name: 'users.view.quwurfl',
            url: '/quwurfl',
            views: {
                'users-quwurfl': {
                    templateUrl: 'view/users.tabs.quwurfl'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User QuWURFL Subscriptions ({id})'
            }
        }).state({
            name: 'users.view.changelog',
            url: '/changelog',
            views: {
                'users-changelog': {
                    templateUrl: 'view/users.tabs.changelog'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'User Recent Activities ({id})'
            }
        });
    }
})();
