(function() {
    'use strict';

    angular.module('adminApp')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider', 'notificationsConfigProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider, notificationsConfigProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'login',
            url: '/login',
            templateUrl: 'view/home.login',
            data: {
                sessionCheck: true,
                niceName: 'Login'
            }
        })
        .state({
            name: 'home',
            url: '/?session',
            templateUrl: 'view/home.index',
            data: {
                sessionCheck: true,
                niceName: 'Home'
            },
            params: {
                session: {
                    value: null,
                    squash: true
                }
            },
        })
        .state({
            name: 'yii',
            url: '/yii?token',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                token: {
                    value: null
                }
            },
            controller: 'YiiLoginController',
            reloadOnSearch: false
        })
        .state({
            name: 'history',
            url: '/history',
            templateUrl: 'view/home.history',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'changelog',
            url: '/changelog?page',
            templateUrl: 'view/home.changelog',
            data: {
                sessionCheck: true,
                niceName: 'Changelog'
            },
            params: {
                page: {
                    value: '1'
                }
            }
        })
        .state({
            name: 'impersonate_imageengine',
            url: '/impersonate/imageengine/:id',
            templateUrl: 'view/components.impersonate_imageengine',
            data: {
                sessionCheck: false,
                niceName: 'Impersonate ImageEngine'
            }
        })
        .state({
            name: 'redirecting',
            url: '/redirecting',
            templateUrl: 'view/components.redirecting',
            data: {
                sessionCheck: true,
                allow: true
            }
        })
        .state("otherwise", { // Show 404 without changing path
            url: "*path",
            templateUrl: "404"
        });

        notificationsConfigProvider.setAutoHide(true);

        // delay before hide
        notificationsConfigProvider.setHideDelay(4000);

        // support HTML
        notificationsConfigProvider.setAcceptHTML(true);

        // Set an animation for hiding the notification
        notificationsConfigProvider.setAutoHideAnimation('fadeOutNotifications');

        // delay between animation and removing the nofitication
        notificationsConfigProvider.setAutoHideAnimationDelay(1500);
    }
})();
