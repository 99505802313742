(function() {
    'use strict';

    angular.module('adminApp.cut')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];
    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'centraltest',
            url: '/centraltest/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'centraltest.default',
            url: '/default',
            templateUrl: 'view/centraltests.nav',
            data: {
                sessionCheck: true,
                niceName: 'WURFL API Centralized Testing Center'
            }
        })
        .state({
            name: 'centraltest.quick_admin',
            url: '/quickadmin',
            templateUrl: 'view/centraltests.quickadmin',
            data: {
                sessionCheck: true,
                niceName: 'Manage Existing WURFL API Centralized Unit Tests'
            }
        })
        .state({
            name: 'centraltest.view_test',
            url: '/test/:id/view',
            templateUrl: 'view/centraltests.view',
            data: {
                sessionCheck: true,
                niceName: 'View WURFL API Centralized Unit Test ({id})'
            },
            params: {
                edit: false
            }
        })
        .state({
            name: 'centraltest.import_xml',
            url: '/import',
            templateUrl: 'view/centraltests.import',
            data: {
                sessionCheck: true,
                niceName: 'Import WURFL API XML Centralized Unit  Tests'
            }
        })
        .state({
            name: 'centraltest.download',
            url: '/download',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'centraltest.download.single',
            url: '/single?test',
            data: {
                sessionCheck: true,
                niceName: 'Download WURFL API Centralized Unit Test'
            },
            params: {
                test: {
                    value: null
                }
            },
            onEnter: function($ngConfirm, $state, $stateParams, centraltestXmlService, Notification) {
                centraltestXmlService.exportSingle($stateParams.test);
            }
        })
        .state({
            name: 'centraltest.download.type',
            url: '/:type',
            data: {
                sessionCheck: true,
                niceName: 'Download WURFL API Centralized Unit Tests'
            },
            onEnter: function($ngConfirm, $state, $stateParams, centraltestXmlService, Notification) {
                var all = false;
                var typeUnit = false
                var typeRegression = false;
                switch ($stateParams.type) {
                    case 'all':
                        all = true;
                        break;
                    case 'unit':
                        typeUnit = true;
                        break;
                    case 'regression':
                        typeRegression = true;
                        break;
                    default:
                        $ngConfirm({
                            title: 'Alert!',
                            theme: 'dark',
                            content: "<p>Unknown parameter</p>",
                            buttons: {
                                close: {
                                    text: 'OK',
                                    btnClass: 'btn-danger'
                                }
                            }
                        });
                        $state.go('centraltest.default');
                    break;
                }
                centraltestXmlService.export(all, typeUnit, typeRegression);
            }
        })
        .state({
            name: 'centraltest.admin',
            url: '/admin',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'centraltest.admin.tests',
            url: '/tests/list?page',
            templateUrl: 'view/centraltests.admin.tests',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Manage WURFL API Centralized Unit Tests'
            }
        })
        .state({
            name: 'centraltest.admin.requests',
            url: '/requests/list?page',
            templateUrl: 'view/centraltests.admin.requests',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Manage Central Test Http Requests'
            }
        })
        .state({
            name: 'centraltest.admin.headers',
            url: '/headers/list?page',
            templateUrl: 'view/centraltests.admin.headers',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Manage Central Test Http Headers'
            }
        })
        .state({
            name: 'centraltest.admin.assertions',
            url: '/assertions/list?page',
            templateUrl: 'view/centraltests.admin.assertions',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Manage Central Test Assertions'
            }
        });
    }
})();
