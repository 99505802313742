(function() {
    'use strict';

    angular.module('adminApp.filters')
        .filter('createDate', createDate);

    function createDate() {
        return function (input) {
            if (input.date !== null && input.date != '0000-00-00 00:00:00') {
                var b = input.date.split(/\D/);
                switch (input.format) {
                    case 'YMD HIS':
                        return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
                        break;
                    case 'YMD':
                        return new Date(b[0], b[1]-1, b[2]);
                        break;
                    default:
                        return new Date();
                }
            } else {
                return new Date();
            }
        }
    }
})();
