(function() {
    'use strict';

    angular.module('adminApp.quwurfl')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'quwurfl',
            url: '/quwurfl/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'quwurfl.view',
            url: '/:id/view',
            templateUrl: 'view/subscriptions.quwurfl.view',
            data: {
                sessionCheck: true,
                niceName: 'QuWURFL Account ({id})'
            },
            resolve: {
                edit: function($stateParams) {
                    return $stateParams.edit;
                },
                editDefaults: function($stateParams) {
                    return $stateParams.editDefaults;
                }
            },
            params: {
                edit: false,
                editDefaults: false
            }
        })
        // .state({
        //     name: 'quwurfl.view.configuration',
        //     url: '/configuration',
        //     views: {
        //         configuration: {
        //             templateUrl: 'view/subscriptions.quwurfl.configuration',
        //             controller: 'quwurflConfigurationController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     data: {
        //         sessionCheck: true,
        //         niceName: 'QuWURFL Account Configuration ({id})'
        //     }
        // })
        // .state({
        //     name: 'quwurfl.view.usage',
        //     url: '/usage',
        //     views: {
        //         usage: {
        //             templateUrl: 'view/subscriptions.quwurfl.usage',
        //             controller: 'quwurflUsageController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     data: {
        //         sessionCheck: true,
        //         niceName: 'QuWURFL Account Usage ({id})'
        //     }
        // })
        // .state({
        //     name: 'quwurfl.view.billing',
        //     url: '/billing',
        //     views: {
        //         billing: {
        //             templateUrl: 'view/subscriptions.common.billing',
        //             controller: 'quwurflBillingController',
        //             controllerAs: 'vm'
        //         }
        //     },
        //     data: {
        //         sessionCheck: true,
        //         niceName: 'QuWURFL Account Billing ({id})'
        //     }
        // })
        .state({
            name: 'quwurfl.list',
            url: '/list?page',
            templateUrl: 'view/subscriptions.quwurfl.list',
            params: {
                page: {
                    value: '0',
                    squash: true
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'QuWURFL Accounts'
            }
        })
        // .state({
        //     name: 'quwurfl.search',
        //     url: '/search/:keyword?page',
        //     templateUrl: 'view/subscriptions.common.search',
        //     params: {
        //         page: {
        //             value: '0',
        //             squash: true
        //         }
        //     },
        //     controller: "quwurflSearchController",
        //     controllerAs: "vm",
        //     data: {
        //         sessionCheck: true,
        //         niceName: 'QuWURFL Account Search'
        //     }
        // })
        // .state({
        //     name: 'quwurfl.account',
        //     url: '/:id/account',
        //     onEnter: function($state, $stateParams, $uibModal) {
        //         $uibModal.open({
        //             templateUrl: 'view/subscriptions.quwurfl.account',
        //             controller: 'quwurflViewAccountController',
        //             controllerAs: 'vm',
        //             size: 'lg',
        //             keyboard: false,
        //             backdrop: 'static'
        //         });
        //     },
        //     data: {
        //         sessionCheck: true,
        //         niceName: 'QuWURFL Account Details ({id})'
        //     }
        // });

        $urlRouterProvider.otherwise('/quwurfl/app/list');
    }
})();
