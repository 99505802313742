(function() {
    'use strict';

    angular.module('adminApp')
        .constant('userRoles', {
            admin: 'Super Administrator',
            salesadmin: 'Internal Administrator',
            user: 'User'
        })
        .constant('INSIGHT_SERVER_URL', 'insight.scientiamobile.com:21')
        .constant('CUT_URL', 'https://cut.scientiamobile.com')
        .constant('WURFL_ADMIN_MICROSERVICE_URL', 'https://wurfl-admin-api.scientiamobile.com')
        .constant('rolePermissions', {
            admin_salesadmin: [
                'users', 'licenses', 'cloud', 'capabilities', 'wit', 'wjs', 'cut', 'apikey', 'home', 'saas', 'wurfl'
            ],
            admin: [
                'coreapi', 'scopes'
            ]
        })
        .constant('IE', {
            basic: 'IMAGEENGINE_BASIC',
            standard: 'IMAGEENGINE_STANDARD',
            pro: 'IMAGEENGINE_PRO',
            trial: 'IMAGEENGINE_TRIAL'
        });
})();
