(function() {
    'use strict';

    angular.module('adminApp.filters')
        .filter('convertDate', convertDate);

    function convertDate() {
        return function(date) {
            if (date <= 72) {
                return date+' hrs';
            }

            var days = Math.round(date / 24);

            return days+' days';
        }
    }
})();
