(function() {
    'use strict';

    angular.module('adminApp.filters')
        .filter('dateSuffix', dateSuffix);

    dateSuffix.$inject = ['$filter']
    function dateSuffix($filter) {
        var suffixes = ["th", "st", "nd", "rd"];
        return function(input) {
            var dtfilter = $filter('date')(input, 'MMMM dd');
            var day = parseInt(dtfilter.slice(-2));
            var relevantDigits = (day < 30) ? day % 20 : day % 30;
            var suffix = (relevantDigits <= 3) ? suffixes[relevantDigits] : suffixes[0];
            return dtfilter+suffix;
        };
    }
})();
