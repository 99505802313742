(function() {
    'use strict';

    angular.module('adminApp.wjs')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'wjs',
            url: '/wjs/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'wjs.view',
            url: '/:id/view',
            templateUrl: 'view/subscriptions.wjs.view',
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Account ({id})'
            },
            resolve: {
                edit: function($stateParams) {
                    return $stateParams.edit;
                },
                editDefaults: function($stateParams) {
                    return $stateParams.editDefaults;
                }
            },
            params: {
                edit: false,
                editDefaults: false
            }
        })
        .state({
            name: 'wjs.view.configuration',
            url: '/configuration',
            views: {
                configuration: {
                    templateUrl: 'view/subscriptions.wjs.configuration',
                    controller: 'WjsConfigurationController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Account Configuration ({id})'
            }
        })
        .state({
            name: 'wjs.view.usage',
            url: '/usage',
            views: {
                usage: {
                    templateUrl: 'view/subscriptions.wjs.usage',
                    controller: 'WjsUsageController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Account Usage ({id})'
            }
        })
        .state({
            name: 'wjs.view.billing',
            url: '/billing',
            views: {
                billing: {
                    templateUrl: 'view/subscriptions.common.billing',
                    controller: 'WjsBillingController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Account Billing ({id})'
            }
        })
        .state({
            name: 'wjs.list',
            url: '/list?page',
            templateUrl: 'view/subscriptions.wjs.list',
            params: {
                page: {
                    value: '0',
                    squash: true
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Accounts'
            }
        })
        .state({
            name: 'wjs.search',
            url: '/search/:keyword?page',
            templateUrl: 'view/subscriptions.common.search',
            params: {
                page: {
                    value: '0',
                    squash: true
                }
            },
            controller: "WjsSearchController",
            controllerAs: "vm",
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Account Search'
            }
        })
        .state({
            name: 'wjs.account',
            url: '/:id/account',
            onEnter: function($state, $stateParams, $uibModal) {
                $uibModal.open({
                    templateUrl: 'view/subscriptions.wjs.account',
                    controller: 'WjsViewAccountController',
                    controllerAs: 'vm',
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static'
                });
            },
            data: {
                sessionCheck: true,
                niceName: 'WURFL.js Account Details ({id})'
            }
        });

        $urlRouterProvider.otherwise('/wjs/app/list');
    }
})();
