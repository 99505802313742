(function() {
    'use strict';

    angular.module('adminApp.licenses')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'licenses',
            url: '/licenses/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'licenses.view',
            url: '/:id/view',
            templateUrl: 'view/licenses.view',
            data: {
                sessionCheck: true,
                niceName: 'License ({id})'
            },
            resolve: {
                edit: function($stateParams) {
                    return $stateParams.edit;
                }
            },
            params: {
                edit: false
            }
        })
        .state({
            name: 'licenses.view.capabilities',
            url: '/capabilities',
            params: {
                license: null
            },
            views: {
                'license-capabilities': {
                    templateUrl: 'view/licenses.templates.capabilities',
                    controller: 'LicenseViewCapabilitiesController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'License Capabilities ({id})'
            }
        })
        .state({
            name: 'licenses.view.configuration',
            url: '/configure/:type',
            params: {
                license: null
            },
            views: {
                'license-configuration': {
                    templateUrl: function($stateParams) {
                        return 'view/licenses.configuration.'+$stateParams.type;
                    }
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'License Configuration ({id})'
            }
        })
        .state({
            name: 'licenses.view.text',
            url: '/text',
            views: {
                'license-text': {
                    templateUrl: 'view/licenses.templates.licensetext',
                    controller: 'LicenseTextController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'License Text ({id})'
            }
        })
        .state({
            name: 'licenses.list',
            url: '/list?page',
            templateUrl: 'view/licenses.list',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Licenses Management'
            }
        })
        .state({
            name: 'licenses.account',
            url: '/:id/account',
            onEnter: function($state, $stateParams, $uibModal) {
                $uibModal.open({
                    templateUrl: 'view/licenses.templates.modal_view_account',
                    controller: 'LicenseViewAccountController',
                    controllerAs: 'vm',
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static'
                });
            },
            data: {
                sessionCheck: true,
                niceName: 'License Account Details ({id})'
            }
        })
        .state({
            name: 'licenses.insight',
            url: '/insight',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'licenses.insight.manage',
            url: '/list?page',
            templateUrl: 'view/licenses.insight.manage',
            data: {
                sessionCheck: true,
                niceName: 'Insight Accounts'
            },
            params: {
                page: {
                    value: '1'
                }
            }
        })
        .state({
            name: 'licenses.insight.index',
            url: '/index/:userId/:id',
            templateUrl: 'view/licenses.insight.index',
            data: {
                sessionCheck: true,
                niceName: 'Insight Account ({id})'
            },
        });
    }
})();
