(function() {
    'use strict';

    angular.module('adminApp.filters')
        .filter('fromTimestamp', fromTimestamp);

    function fromTimestamp() {
        return function (timestamp) {
            return new Date(timestamp*1000);
        };
    }
})();
