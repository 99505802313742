(function() {
    'use strict';

    angular.module('adminApp.apikey')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];
    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'apikey',
            url: '/apikey/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'apikey.manage',
            url: '/list?page',
            templateUrl: 'view/apikey.manage',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Manage Api Keys'
            }
        })
        .state({
            name: 'apikey.view',
            url: '/:id/view',
            templateUrl: 'view/apikey.view',
            data: {
                sessionCheck: true,
                niceName: 'View Api Key ({id})'
            },
            resolve: {
                edit: function($stateParams) {
                    return $stateParams.edit;
                }
            },
            params: {
                edit: false
            }
        });
    }
})();
