(function() {
    'use strict';

    angular.module('adminApp.external')
        .config(states);

    states.$inject = ['$locationProvider', '$stateProvider'];

    function states($locationProvider, $stateProvider) {
        $locationProvider.html5Mode(true);

        $stateProvider.state({
            name: 'external',
            url: '/external/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true,
                allow: true
            }
        })
        .state({
            name: 'external.filex',
            url: '/filex?token',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                token: {
                    value: null
                }
            },
            controller: 'ExternalFilexController',
            reloadOnSearch: false
        })
        .state({
            name: 'external.vault',
            url: '/vault?session',
            data: {
                sessionCheck: true,
                allow: true
            },
            params: {
                session: {
                    value: null
                }
            },
            controller: 'ExternalVaultController',
            reloadOnSearch: false
        });
    }
})();
