(function() {
    'use strict';

    angular.module('adminApp.filters')
        .filter('found', found);

    function found() {
        return function(target, keyword) {
            if (target.toString().toLowerCase().indexOf(keyword.toLowerCase())==-1) {
                return target;
            }
            return '<span class="bg-warning"> '+target+' </span>';
        };
    }
})();
