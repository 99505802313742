(function() {
    'use strict';

    angular.module('adminApp.coreapi')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'coreapi',
            url: '/coreapi/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'coreapi.home',
            url: '/home',
            templateUrl: 'view/coreapi.clients',
            data: {
                sessionCheck: true,
                niceName: 'CoreApi OAuth Applications'
            }
        })
        .state({
            name: 'coreapi.view',
            url: '/:id/view',
            templateUrl: 'view/coreapi.view',
            data: {
                sessionCheck: true,
                niceName: 'View CoreAPI Client ({id})'
            }
        })
        .state({
            name: 'scopes',
            url: '/coreapi/scopes/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'scopes.home',
            url: '/home',
            templateUrl: 'view/coreapi.scopes',
            data: {
                sessionCheck: true,
                niceName: 'CoreApi OAuth Scopes'
            }
        })
        .state({
            name: 'scopes.view',
            url: '/:id/view',
            templateUrl: 'view/coreapi.view_scope',
            data: {
                sessionCheck: true,
                niceName: 'View CoreAPI Scope ({id})'
            }
        });
    }
})();
