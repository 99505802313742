(function() {
    'use strict';

    angular.module('adminApp.wurfl')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'capabilities',
            url: '/capabilities/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        }).state({
            name: 'capabilities.list',
            url: '/list?page&capability&description',
            templateUrl: 'view/wurfl.list',
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                capability: {
                    value: "",
                    squash: true
                },
                description: {
                    value: "",
                    squash: true
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'WURFL Capabilities'
            }
        });
    }
})();
