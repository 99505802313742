(function() {
    'use strict';

    angular.module('adminApp.cloud')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'cloud',
            url: '/cloud/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'cloud.view',
            url: '/:id/view',
            templateUrl: 'view/subscriptions.cloud.view',
            data: {
                sessionCheck: true,
                niceName: 'Cloud Account ({id})'
            },
            resolve: {
                edit: function($stateParams) {
                    return $stateParams.edit;
                },
                editDefaults: function($stateParams) {
                    return $stateParams.editDefaults;
                }
            },
            params: {
                edit: false,
                editDefaults: false
            }
        })
        .state({
            name: 'cloud.view.configuration',
            url: '/configuration',
            views: {
                configuration: {
                    templateUrl: 'view/subscriptions.cloud.configuration',
                    controller: 'CloudConfigurationController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Cloud Account Configuration ({id})'
            }
        })
        .state({
            name: 'cloud.view.usage',
            url: '/usage',
            views: {
                usage: {
                    templateUrl: 'view/subscriptions.cloud.usage',
                    controller: 'CloudUsageController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Cloud Account Usage ({id})'
            }
        })
        .state({
            name: 'cloud.view.billing',
            url: '/billing',
            views: {
                billing: {
                    templateUrl: 'view/subscriptions.common.billing',
                    controller: 'CloudBillingController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Cloud Account Billing ({id})'
            }
        })
        .state({
            name: 'cloud.list',
            url: '/list?page',
            templateUrl: 'view/subscriptions.cloud.list',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Cloud Accounts'
            }
        })
        .state({
            name: 'cloud.search',
            url: '/search/:keyword?page',
            templateUrl: 'view/subscriptions.common.search',
            params: {
                page: {
                    value: '0',
                    squash: true
                }
            },
            controller: "CloudSearchController",
            controllerAs: "vm",
            data: {
                sessionCheck: true,
                niceName: 'Cloud Account Search'
            }
        })
        .state({
            name: 'cloud.account',
            url: '/:id/account',
            onEnter: function($state, $stateParams, $uibModal) {
                $uibModal.open({
                    templateUrl: 'view/subscriptions.cloud.account',
                    controller: 'CloudViewAccountController',
                    controllerAs: 'vm',
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static'
                });
            },
            data: {
                sessionCheck: true,
                niceName: 'Cloud Account Details ({id})'
            }
        })
        .state({
            name: 'cloud.top',
            url: '/topusers',
            templateUrl: 'view/subscriptions.cloud.top_users',
            data: {
                sessionCheck: true,
                niceName: 'WURFL Cloud Top Users'
            }
        })
        .state({
            name: 'cloud.history',
            url: '/:id/usagehistory',
            templateUrl: 'view/subscriptions.cloud.usage_history',
            data: {
                sessionCheck: true,
                niceName: 'Cloud Subscription Usage History ({id})'
            }
        })
        .state({
            name: 'cloud.usage',
            url: '/usagehistory',
            templateUrl: 'view/subscriptions.cloud.total_usage_history',
            data: {
                sessionCheck: true,
                niceName: 'Cloud Subscription Total Usage History'
            }
        })
        .state({
            name: 'cloud.signups',
            url: '/signups',
            templateUrl: 'view/subscriptions.cloud.signups',
            data: {
                sessionCheck: true,
                niceName: 'Cloud Subscription Signups'
            }
        });

        $urlRouterProvider.otherwise('/cloud/app/list');
    }
})();
