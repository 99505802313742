(function() {
    'use strict';

    angular.module('adminApp.wit')
        .config(states);

    states.$inject = ['$stateProvider', '$locationProvider', '$interpolateProvider', '$provide', '$windowProvider', '$urlRouterProvider'];

    function states($stateProvider, $locationProvider, $interpolateProvider, $provide, $windowProvider, $urlRouterProvider) {
        $locationProvider.html5Mode(true);
        $interpolateProvider.startSymbol('<%');
        $interpolateProvider.endSymbol('%>');

        var $window = $windowProvider.$get();

        $provide.decorator('$uiViewScroll', function($delegate) {
            return function(uiViewElement) {
                $window.scrollTo(0, 0);
            };
        });

        $stateProvider.state({
            name: 'wit',
            url: '/wit/app',
            abstract: true,
            template: '<ui-view/>',
            data: {
                sessionCheck: true
            }
        })
        .state({
            name: 'wit.view',
            url: '/:id/view',
            templateUrl: 'view/subscriptions.wit.view',
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Account ({id})'
            }
        })
        .state({
            name: 'wit.view.configuration',
            url: '/configuration',
            templateUrl: 'view/subscriptions.wit.view',
            views: {
                configuration: {
                    templateUrl: 'view/subscriptions.wit.configuration',
                    controller: 'WitConfigurationController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Account Configuration ({id})'
            }
        })
        .state({
            name: 'wit.view.domain',
            url: '/cache',
            templateUrl: 'view/subscriptions.wit.view',
            views: {
                domain: {
                    templateUrl: 'view/subscriptions.wit.cache',
                    controller: 'WitCacheController',
                    controllerAs: 'vm'
                }
            }
        })
        .state({
            name: 'wit.view.usage',
            url: '/usage',
            views: {
                usage: {
                    templateUrl: 'view/subscriptions.wit.usage',
                    controller: 'WitUsageController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Account Usage ({id})'
            }
        })
        .state({
            name: 'wit.view.billing',
            url: '/billing',
            views: {
                billing: {
                    templateUrl: 'view/subscriptions.wit.account_billing',
                    controller: 'WitViewBillingController',
                    controllerAs: 'vm'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Account Billing ({id})'
            }
        })
        .state({
            name: 'wit.list',
            url: '/list?page',
            templateUrl: 'view/subscriptions.wit.list',
            params: {
                page: {
                    value: '1'
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Accounts'
            }
        })
        .state({
            name: 'wit.search',
            url: '/search/:keyword?page',
            templateUrl: 'view/subscriptions.common.search',
            params: {
                page: {
                    value: '0',
                    squash: true
                }
            },
            controller: "WitSearchController",
            controllerAs: "vm",
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Account Search'
            }
        })
        .state({
            name: 'wit.billing',
            url: '/billing',
            templateUrl: 'view/subscriptions.wit.billing',
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Billing'
            }
        })
        .state({
            name: 'wit.triallinks',
            url: '/triallinks?page',
            templateUrl: 'view/subscriptions.wit.triallinks',
            params: {
                page: {
                    value: '1',
                    squash: true
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Trial Account Links'
            }
        })
        .state({
            name: 'wit.newregion',
            url: '/region',
            templateUrl: 'view/subscriptions.wit.regions',
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Attach Domains to New Region'
            }
        })
        .state({
            name: 'wit.account',
            url: '/:id/account',
            onEnter: function($state, $stateParams, $uibModal) {
                $uibModal.open({
                    templateUrl: 'view/subscriptions.wit.account',
                    controller: 'WitViewAccountController',
                    controllerAs: 'vm',
                    size: 'lg',
                    keyboard: false,
                    backdrop: 'static'
                });
            },
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Account Details ({id})'
            }
        })
        .state({
            name: 'wit.refresh',
            url: '/refresh?state&code&realmId',
            onEnter: function($state, $stateParams, $uibModal) {
                $uibModal.open({
                    templateUrl: 'view/subscriptions.wit.refresh_qb',
                    controller: 'QuickbooksController',
                    controllerAs: 'vm',
                    size: 'md',
                    keyboard: false,
                    backdrop: 'static'
                })
            },
            params: {
                state: {
                    value: null
                },
                code: {
                    value: null
                },
                realmId: {
                    value: null
                }
            },
            data: {
                sessionCheck: true,
                niceName: 'Quickbooks Refresh Token'
            }
        })
        .state({
            name: 'wit.statistics',
            url: '/statistics',
            templateUrl: 'view/subscriptions.wit.trial_statistics',
            data: {
                sessionCheck: true,
                niceName: 'ImageEngine Trial Statistics'
            }
        });

        $urlRouterProvider.otherwise('/wit/app/list');
    }
})();
